import React from 'react';

function Services() {
  return (
    <div>
      <h1>Our Services</h1>
      <ul>
        <li>Luxury Vehicle Acquisition</li>
        <li>Personalized Consultation</li>
        <li>Exclusive Vehicle Sales</li>
        <li>Vehicle Financing Assistance</li>
      </ul>
    </div>
  );
}

export default Services;
