import React from 'react';

function Testimonials() {
  return (
    <div>
      <h1>Testimonials</h1>
      <p>Hear from our satisfied clients and their experiences with McKinley Asset Solutions.</p>
    </div>
  );
}

export default Testimonials;
