import React from 'react';

function Blog() {
  return (
    <div>
      <h1>Our Blog</h1>
      <p>Read the latest updates and insights from the luxury automotive world.</p>
    </div>
  );
}

export default Blog;
